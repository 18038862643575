<template>
  <div>
    <font-awesome-icon :icon="['fas', icon]" v-if="lib == 'fas'" />
    <v-icon v-else-if="lib == 'mdi'">{{ icon }}</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    icon: [Array, String],
    lib: String
  }
}
</script>
