<template>
  <v-text-field
    v-bind="$attrs"
    v-on="visibility"
    :rules="disableValidation ? [] : [value => rules.date(value) || errorMessage]"
    @blur="validateDate"
    :hide-details="hideDetails"
    filled
    dense
  />
</template>

<script>
import { rules } from '../../validation/rules'
import i18n from '../../i18n'
import ErrorHelper from '../../error/error'

const defaultDatePickerLimitForSelectedDates = require('@/config').default.datePickerLimitForSelectedDates

export default {
  props: {
    visibility: Object,
    errorMessage: String,
    hideDetails: {
      Boolean,
      default: true
    },
    disableValidation: {
      Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: { ...rules }
    }
  },
  methods: {
    validateDate(event) {
      if (this.disableValidation) {
        return
      }

      // The value must be between today and today-defaultDatePickerLimitForSelectedDates
      if (event.target.value) {
        // Check if date is valid
        const pattern = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/
        if (!pattern.test(event.target.value)) {
          ErrorHelper.show(this.errorMessage)
          return false
        }

        // Check if date is in range
        let enteredDate = new Date(event.target.value)
        let start = new Date()
        let end = new Date()
        start.setDate(start.getDate() - defaultDatePickerLimitForSelectedDates)
        let isInRange = start.valueOf() <= enteredDate.valueOf() && enteredDate.valueOf() <= end.valueOf()
        if (!isInRange) {
          ErrorHelper.show(`${this.errorMessage}. ${i18n.t('errors.date_range_limitation', { limitation: defaultDatePickerLimitForSelectedDates })}`)
          return isInRange
        }
      }
      return true
    }
  }
}
</script>
