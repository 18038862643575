<template>
  <div>
    <v-row class="mb-4">
      <v-col class="col-12 col-xl-2 col-lg-3 col-sm-6 revenue">
        <DashboardCard icon="coins" :title="$t('dashboard.revenue_last30')" :text="'$ ' + $f.number(sum.revenue, 0, 0)" />
      </v-col>
      <v-col class="col-12 col-xl-2 col-lg-3 col-sm-6 uniques">
        <DashboardCard icon="mdi-account-multiple-outline" :title="$t('dashboard.uniques_last30')" :text="$f.number(sum.uniques, 0, 0)" />
      </v-col>
      <v-col class="col-12 col-xl-2 col-lg-3 col-sm-6 clicks">
        <DashboardCard icon="mdi-cursor-default-click" :title="$t('dashboard.clicks_last30')" :text="$f.number(sum.clicks, 0, 0)" />
      </v-col>
      <v-col class="col-12 col-xl-2 col-lg-3 col-sm-6 domains">
        <DashboardCard
          icon="mdi-domain"
          :title="$t('dashboard.active_domains_last30')"
          :text="$f.number(userStats.domains_with_revenue_last30, 0, 0)"
        />
      </v-col>
    </v-row>
    <StatsAccountDate :enable-filters="false" :enable-heading="false" :enable-sums="false" />
  </div>
</template>

<script>
import DashboardCard from '../../components/dashboard/DashboardCard'
import StatsAccountDate from '../../components/stats/account/StatsAccountDate'
import Logger from '@/util/Logger'

export default {
  components: {
    DashboardCard,
    StatsAccountDate
  },
  data() {
    return {
      userStats: {
        domains_with_revenue_last30: 0
      }
    }
  },
  computed: {
    userData() {
      return this.$store.getters['user/getUserData']
    },
    sum() {
      return this.$store.getters['stats_user_date/getSum']
    }
  },
  mounted() {
    this.$store
      .dispatch('stats_user/fetchData')
      .then(response => {
        this.userStats = response.data[0]
      })
      .catch(error => {
        Logger.log('Error catched ', { error })
      })
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'
.theme-default
  #dashboard
    .mdi-account-multiple-outline::before
      color: #3046C5

    .dashboard-card-heading
      position: absolute
      top: -10px
      width: 56px
      height: 56px

    .dashboard-card-content
      margin-right: 20px
      border: none

    .dashboard-card-title
      font-size: 14px
      color: $secondary-text
      padding-left: 70px

    .dashboard-card-text
      font-size: 20px
      padding-left: 70px

    /*Icon Box*/
    .dashboard-card-heading
      top: 10px
    .elevation-6
      box-shadow: none !important

    .col-12
      padding-bottom: 0
    .revenue .dashboard-card-heading
      color: #13CB3C !important
      background-color: #F2FFF5 !important
      border-color: #F2FFF5 !important
      .svg-inline--fa
        font-size: 24px
    .uniques .dashboard-card-heading
      color: #3046C5
      background-color: #F2F4FF !important
      border-color: #F2F4FF !important
    .clicks .dashboard-card-heading
      color: #3DA5D9 !important
      background-color: #F2FBFF !important
      border-color: #F2FBFF !important
      .mdi-cursor-default-click::before
        color: #3DA5D9 !important
    .domains .dashboard-card-heading
      background-color: #FEFBF1 !important
      border-color: #FEFBF1 !important
      .mdi-domain::before
        color: #FEC601 !important
</style>
