import DateHelper from '@/util/DateHelper'
import Logger from '@/util/Logger'

let StatsBaseDateGridMixin = {
  methods: {
    defaultTo: () => DateHelper.todayAsString(),
    defaultFrom: () => DateHelper.daysAgoAsString(29)
  },
  mounted() {
    this.$store
      .dispatch('finished_imports_pending/fetchData')
      .then(() => {
        let pendingImports = this.$store.getters['finished_imports_pending/getData']
        let date = this.$store.getters['finished_imports_pending/getLastFinishedDay']
        this.lastFinishedDate = DateHelper.toDateString(new Date(date))
        this.pendingImports = pendingImports.data.flat()
        Logger.log('Using last finished date', this.lastFinishedDate)
      })
      .catch(error => {
        Logger.log('Error loading finished imports', { error })
      })
  }
}

export default StatsBaseDateGridMixin
