<template>
  <v-select
    class="date-range-selector"
    hide-details
    v-model="active"
    :label="label"
    :items="getItems"
    item-text="label"
    item-value="type"
    :menu-props="{ bottom: true, offsetY: true }"
    filled
    dense
  />
</template>

<script>
export default {
  props: {
    /**
     * Label to use for the range selector
     */
    label: {
      type: String
    },
    /**
     * Pass a from date to sync
     */
    from: {
      type: String
    },
    /**
     * Pass a to date to sync
     */
    to: {
      type: String
    },
    /**
     * The default range that should be marked as active
     */
    defaultActiveRange: {
      type: String,
      default: 'last30'
    },
    /**
     * Prop to set the current day by default Date() is used when nothing is passed
     */
    currentDay: {
      type: String,
      default: ''
    },
    /**
     * Day that should be used as lasted finished day
     */
    lastFinishedDay: {
      type: String,
      default: ''
    },
    showTypes: {
      type: Array,
      default: () => ['last30', 'last7', 'lastUpdated30', 'lastUpdated7', 'lastMonth', 'custom']
    }
  },
  data() {
    return {
      active: this.defaultActiveRange,
      items: [
        {
          label: this.$t('stats.last30'),
          type: 'last30',
          to: () => {
            return this.dateString(this.today())
          },
          from: () => {
            let date = this.today()
            date.setDate(date.getDate() - 29)
            return this.dateString(date)
          }
        },
        {
          label: this.$t('stats.last7'),
          type: 'last7',
          to: () => {
            return this.dateString(this.today())
          },
          from: () => {
            let date = this.today()
            date.setDate(date.getDate() - 6)
            return this.dateString(date)
          }
        },
        {
          label: this.$t('stats.lastUpdated30'),
          type: 'lastUpdated30',
          to: () => {
            return this.dateString(this.finishedDayToUse())
          },
          from: () => {
            let date = this.finishedDayToUse()
            date.setDate(date.getDate() - 29)
            return this.dateString(date)
          }
        },
        {
          label: this.$t('stats.lastUpdated7'),
          type: 'lastUpdated7',
          to: () => {
            return this.dateString(this.finishedDayToUse())
          },
          from: () => {
            let date = this.finishedDayToUse()
            date.setDate(date.getDate() - 6)
            return this.dateString(date)
          }
        },
        {
          label: this.$t('stats.lastMonth'),
          type: 'lastMonth',
          to: () => {
            let today = this.today()
            let lastDay = new Date(today.getFullYear(), today.getMonth(), 1)
            return this.dateString(lastDay)
          },
          from: () => {
            let today = this.today()
            let firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 2)
            return this.dateString(firstDay)
          }
        },
        {
          label: this.$t('stats.custom'),
          type: 'custom'
        }
      ]
    }
  },
  watch: {
    from: function() {
      this.syncActiveDateRange()
    },
    to: function() {
      this.syncActiveDateRange()
    },
    active: function(value) {
      this.syncFromTo(value)
    }
  },
  methods: {
    syncActiveDateRange() {
      this.active = this.getRange(this.from, this.to)
    },
    syncFromTo(range) {
      let item = this.getFromTo(range)
      if (item !== null) {
        this.$emit('update_date_range', item.from(), item.to())
      }
    },
    getRange(from, to) {
      let range = 'custom'
      // check if an available range matches and set is as active
      this.items.forEach(items => {
        if (typeof items.from !== 'function' || typeof items.to !== 'function') return
        if (items.from() !== from) return
        if (items.to() !== to) return
        range = items.type
      })

      return range
    },
    getFromTo(rangeType) {
      let matchingItem = null
      this.items.forEach(item => {
        if (typeof item.from !== 'function' || typeof item.to !== 'function') return
        if (item.type === rangeType) {
          matchingItem = item
        }
      })

      return matchingItem
    },
    dateString(date) {
      return date.toISOString().substr(0, 10)
    },
    today() {
      if (this.currentDay !== '') {
        return new Date(this.currentDay)
      }

      let today = new Date()
      today.setHours(23, 59, 0, 0)
      return today
    },
    finishedDayToUse() {
      if (this.lastFinishedDay !== '') {
        return new Date(this.lastFinishedDay)
      }

      // by default we use one day ago
      let date = new Date()
      date.setDate(date.getDate() - 1)
      date.setHours(23, 59, 0, 0)
      return date
    }
  },
  computed: {
    getItems() {
      return this.items.filter(item => this.showTypes.includes(item.type))
    }
  }
}
</script>
