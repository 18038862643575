<template>
  <div id="dashboard">
    <Headline :text="$t('dashboard.heading')" />
    <DashboardIndex />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import DashboardIndex from '../../components/dashboard/DashboardIndex'

export default {
  components: {
    Headline,
    DashboardIndex
  }
}
</script>
