<template>
  <div class="line-chart">
    <apexchart type="line" :height="height" :options="chartOptions" :series="seriesParsed"></apexchart>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts'

export default {
  components: {
    apexchart
  },
  props: {
    data: Array,
    dateField: {
      type: String,
      default: 'date'
    },
    series: Array,
    xaxistype: {
      type: String,
      default: 'date'
    },
    xaxis: Array,
    yaxisDefaultLabel: {
      type: String,
      default: ''
    },
    xunit: String,
    horizontal: String,
    height: {
      type: String,
      default: '350'
    },
    colors: {
      type: Array,
      default: () => {
        return ['#13cb3c', '#3046c5', '#e5a224', '#10DED8', '#10DE6C', '#e6e6e6']
      }
    }
  },
  data() {
    return {
      seriesParsed: [],
      chartOptions: {}
    }
  },
  watch: {
    data: {
      handler() {
        this.initialize()
      }
    }
  },
  methods: {
    initialize: function() {
      let parsedXaxis = this.getParsedXAxis()
      this.seriesParsed = this.getParsedSeries(parsedXaxis)
      let parsedYaxis = this.getParsedYAxis(this.seriesParsed)
      this.chartOptions = this.getChartOptions(parsedXaxis, parsedYaxis)
    },
    getChartOptions: function(parsedXaxis, parsedYaxis) {
      return {
        chart: {
          toolbar: {
            tools: {
              download: false,
              reset: '<i aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-refresh "></i>',
              pan: false,
              zoom: false,
              zoomin: '<i aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-plus-circle-outline "></i>',
              zoomout: '<i aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-minus-circle-outline "></i>'
            }
          },
          fontFamily: 'Roboto, sans-serif',
          foreColor: '#000000DE'
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          formatter: (seriesName, opts) => {
            return this.seriesParsed[opts.seriesIndex]['label']
          }
        },
        plotOptions: {
          bar: {
            horizontal: this.horizontal === 'true' ? true : false
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: parsedXaxis.length > 0 ? parsedXaxis : [],
          labels: {
            formatter: value => {
              if (this.xaxistype !== 'date') return value
              let date = Date.parse(value)
              if (isNaN(date)) return value

              if (this.xunit === 'week') {
                return this.$f.shortWeek(value)
              } else if (this.xunit === 'month') {
                return this.$f.shortMonth(value)
              } else if (this.xunit === 'year') {
                return this.$f.shortYear(value)
              }
              return value
            }
          }
        },
        yaxis: parsedYaxis,
        fill: {
          opacity: 0.8
        },
        stroke: {
          width: 2,
          curve: 'smooth'
        },
        tooltip: {
          y: {
            formatter: (val, { seriesIndex }) => {
              let fraction = Number.isInteger(val) ? 0 : 2
              let formattedValue = this.$f.number(val, 2, fraction)
              return String(this.seriesParsed[seriesIndex]['unit'] + ' ' + formattedValue).trim()
            }
          }
        }
      }
    },
    /**
     * Generating of x-axes data
     */
    getParsedXAxis: function() {
      if (this.xaxistype !== 'date') {
        return this.xaxis
      }

      let parsedXaxis = []
      // go through the dates
      let dateForLoopFrom = new Date(this.xaxis[0])
      let dateForLoopTo = new Date(this.xaxis[1])
      // Set time to end of day, because we get sometimes two times the same day
      dateForLoopFrom.setHours(23, 59, 59)
      dateForLoopTo.setHours(23, 59, 59)
      for (dateForLoopFrom; dateForLoopFrom <= dateForLoopTo; this.getNextDate(dateForLoopFrom)) {
        let enFullDate = dateForLoopFrom.toISOString().split('T')[0]
        parsedXaxis.push(enFullDate)
      }
      return parsedXaxis
    },
    /**
     * Retrieves the next date depending on the xunit
     */
    getNextDate(date) {
      if (this.xunit === 'day') {
        date.setDate(date.getDate() + 1)
      } else if (this.xunit === 'week') {
        date.setDate(date.getDate() + 7)
      } else if (this.xunit === 'month') {
        date.setMonth(date.getMonth() + 1)
      } else if (this.xunit === 'year') {
        date.setFullYear(date.getFullYear() + 1)
      }
      return date
    },
    /**
     * Parsing the series for the vue component.
     *
     * @param parsedXaxis
     * @returns {[]}
     */
    getParsedSeries: function(parsedXaxis) {
      // calculate through data and generate series array for apexchart
      let seriesParsed = []
      this.series.forEach((currentSeries, currentKey) => {
        let seriesDataKey = currentSeries['data_key']
        let seriesName = seriesDataKey.charAt(0).toUpperCase() + seriesDataKey.slice(1)
        let currentParsedSeries = {
          name: seriesName,
          type: currentSeries['type'] || 'line',
          yaxis: currentSeries['yaxis'] || {},
          yaxis_opposite: currentSeries['yaxis_opposite'] || false,
          color: currentSeries['color'] || this.colors[currentKey],
          unit: currentSeries['unit'] || '',
          label: currentSeries['label'] || seriesName,
          data: []
        }

        if (this.xaxistype === 'date') {
          parsedXaxis.forEach(currentXAxisData => currentParsedSeries.data.push(this.getSeriesForXAxisItem(currentXAxisData, seriesDataKey)))
        } else {
          this.data.forEach(currentData => currentParsedSeries.data.push(currentData[seriesDataKey]))
        }
        seriesParsed.push(currentParsedSeries)
      })

      return seriesParsed
    },
    /**
     * Retrieves the value from the series for a certain XAxis item.
     *
     * @param currentXAxisData
     * @param currentSeriesKey
     * @returns {number|*}
     */
    getSeriesForXAxisItem: function(currentXAxisData, currentSeriesKey) {
      for (let currentDataKey in this.data) {
        let currentData = this.data[currentDataKey]
        if (currentXAxisData === currentData[this.dateField]) {
          return currentData[currentSeriesKey]
        }
      }
      return 0
    },
    /**
     * Uses the series configuration to generate custom YAxis configurations.
     *
     * @param parsedSeries
     * @returns {[]}
     */
    getParsedYAxis: function(parsedSeries) {
      let yaxisParsed = []
      // add the yaxis in the same order as the series
      parsedSeries.forEach(series => {
        if (series['yaxis'] && series['yaxis'] === true) {
          yaxisParsed.push({
            seriesName: series['name'],
            showAlways: true,
            opposite: series['yaxis_opposite'],
            decimalsInFloat: 0,
            axisTicks: {
              show: true,
              color: series['color']
            },
            axisBorder: {
              show: true
            },
            labels: {
              style: {
                colors: series['color']
              },
              formatter: value => {
                let fraction = Number.isInteger(value) ? 0 : 2
                let formattedValue = this.$f.number(value, 2, fraction)
                return String(series['unit'] + ' ' + formattedValue).trim()
              }
            },
            title: {
              text: series['label'],
              style: {
                color: series['color']
              }
            }
          })
        }
      })

      // the default yaxis labels needs to be added as last one
      if (this.yaxisDefaultLabel !== '') {
        yaxisParsed.push({ title: { text: this.yaxisDefaultLabel } })
      }

      return yaxisParsed
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'
.theme-default
  .apexcharts-reset-icon
    margin-left: 0 !important

  .apexcharts-zoomin-icon, .apexcharts-zoomout-icon, .apexcharts-reset-icon, .apexcharts-menu-icon
    transform: none !important
    display: contents
    color: $secondary-text !important

  .apexcharts-toolbar
    z-index: 0 !important
    padding: 0 !important

  .apexcharts-legend-text
    &:hover
      color: $primary !important
    font-size: 0.875rem!important

  .apexcharts-toolbar, .apexcharts-yaxis-title
    @media (max-width: 767px)
      display: none!important

  .line-chart
    @media (max-width: 767px)
      margin-left: -35px
      margin-right: -35px
</style>
