<template>
  <div class="stats-account-date">
    <Grid
      data-store-action="stats_user_date/fetchData"
      :headers="headers"
      :enable-filters="enableFilters"
      :enable-pagination="enablePagination"
      :default-sort-by="defaultSortBy"
      :default-sort-desc="defaultSortDesc"
      :additional-api-arguments="apiArgs"
      :download-link-arguments="downloadLinkArguments"
      form-name="stats-account-date-form"
    >
      <!-- filter slot with additional filter -->
      <template slot="filters" v-if="enableFilters">
        <v-col class="col-12 col-sm-4 col-md-2 filter-date-from">
          <DaySelector
            :label="$t('grid.from')"
            :date="apiArgs.persistent.from.value"
            :error-message="$t('grid.invalid_date', { type: 'from' })"
            :min-date="undefined"
            @update_date="updateDate($event, 'from')"
            content-class="filter-date-from-menu"
          />
        </v-col>
        <v-col class="col-12 col-sm-4 col-md-2 filter-date-to">
          <DaySelector
            :label="$t('grid.to')"
            :date="apiArgs.persistent.to.value"
            :error-message="$t('grid.invalid_date', { type: 'to' })"
            :min-date="undefined"
            @update_date="updateDate($event, 'to')"
            content-class="filter-date-to-menu"
          />
        </v-col>
        <v-col class="col-12 col-sm-4 col-md-2 filter-date-range">
          <date-range
            :label="$t('stats.date_range')"
            @update_date_range="updateDateRange"
            :from="apiArgs.persistent.from.value"
            :to="apiArgs.persistent.to.value"
            :last-finished-day="lastFinishedDate"
            :menu-props="{ contentClass: 'filter-date-range-menu' }"
          />
        </v-col>
      </template>

      <!-- middle slot with bar chart -->
      <template v-slot:middle="{ items }" v-if="apiArgs.persistent.from.value">
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col class="col-12">
                <LineChart
                  :data="items"
                  :series="[
                    { data_key: 'revenue', yaxis: true, unit: '$', type: 'line', label: $t('stats.revenue_chart') },
                    { data_key: 'uniques', yaxis: true, yaxis_opposite: true, type: 'line', label: $t('stats.uniques_chart') }
                  ]"
                  :xaxis="[apiArgs.persistent.from.value, apiArgs.persistent.to.value]"
                  xunit="day"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>

      <!-- data table slots -->
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <router-link
              :to="{ name: 'stats_domains', query: { sort: '-revenue', stats_type: 'custom_date', is_deleted: 0, custom_date: item.date } }"
            >
              <span class="date">{{ item.date }}</span>
            </router-link>
            <span class="weekday mx-2">{{ DateHelper.getWeekday(item.date) }}</span>
            <span class="stars">{{ markPendingImport(item.date) }}</span>
          </td>
          <td class="text-right">{{ $f.number(item.uniques, 0, 0) }}</td>
          <td class="text-right">{{ $f.number(item.clicks, 0, 0) }}</td>
          <td class="text-right">{{ $f.number(item.ctr) }}%</td>
          <td class="text-right">{{ $f.price(item.rpc, 'USD', true) }}</td>
          <td class="text-right">{{ $f.price(item.rpm, 'USD', true) }}</td>
          <td class="text-right">{{ $f.price(item.revenue, 'USD', true) }}</td>
        </tr>
      </template>
      <template slot="body.append" v-if="enableSums || enableAvgs">
        <tr class="font-weight-bold" v-if="enableSums">
          <td>{{ $t('grid.total') }}</td>
          <td class="text-right">{{ $f.number(sum.uniques, 0, 0) }}</td>
          <td class="text-right">{{ $f.number(sum.clicks, 0, 0) }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right">{{ $f.price(sum.revenue, 'USD', true) }}</td>
        </tr>
        <tr class="font-weight-bold" v-if="enableAvgs">
          <td>{{ $t('grid.avg') }}</td>
          <td class="text-right">{{ $f.number(avg.uniques, 0, 0) }}</td>
          <td class="text-right">{{ $f.number(avg.clicks, 0, 0) }}</td>
          <td class="text-right">{{ $f.number(avg.ctr) }}%</td>
          <td class="text-right">{{ $f.price(avg.rpc, 'USD', true) }}</td>
          <td class="text-right">{{ $f.price(avg.rpm, 'USD', true) }}</td>
          <td class="text-right">{{ $f.price(avg.revenue, 'USD', true) }}</td>
        </tr>
      </template>
    </Grid>

    <v-row class="mt-12 mb-4">
      <v-col class="col-12">
        <v-alert dense outlined class="revenue-delay-info">{{ $t('stats.revenue_delayinfo') }}</v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DaySelector from '@/components/date/DaySelector'
import LineChart from '@/components/charts/LineChart'
import DateRange from '@/components/date/DateRange'
import Grid from '@/components/grid/Grid'
import GridBaseMixin from '@/components/grid/GridBaseMixin'
import StatsBaseGridMixin from '@/components/stats/mixins/StatsBaseGridMixin'
import StatsBaseDateGridMixin from '@/components/stats/mixins/StatsBaseDateGridMixin'
import DateHelper from '@/util/DateHelper'

export default {
  computed: {
    DateHelper() {
      return DateHelper
    }
  },
  mixins: [GridBaseMixin, StatsBaseGridMixin, StatsBaseDateGridMixin],
  components: {
    DaySelector,
    LineChart,
    DateRange,
    Grid
  },
  data() {
    return {
      storeName: 'stats_user_date',
      export: {
        fileNamePrefix: 'stats-date',
        apiArgumentsToUse: ['from', 'to']
      },
      data: {},
      defaultSortBy: ['date'],
      defaultSortDesc: [true],
      lastFinishedDate: this.defaultTo(),
      pendingImports: [],
      headers: [
        { text: this.$t('stats.date'), value: 'date', sortable: true, align: 'start' },
        {
          text: this.$t('stats.uniques'),
          value: 'uniques',
          sortable: false,
          align: 'right',
          description: this.$t('stats.uniques_description')
        },
        {
          text: this.$t('stats.clicks'),
          value: 'clicks',
          sortable: false,
          align: 'right',
          description: this.$t('stats.clicks_description')
        },
        { text: this.$t('stats.ctr'), value: 'ctr', sortable: false, align: 'right', description: this.$t('stats.ctr_description') },
        { text: this.$t('stats.rpc'), value: 'rpc', sortable: false, align: 'right', description: this.$t('stats.rpc_description') },
        { text: this.$t('stats.rpm'), value: 'rpm', sortable: false, align: 'right', description: this.$t('stats.rpm_description') },
        {
          text: this.$t('stats.revenue'),
          value: 'revenue',
          sortable: false,
          align: 'right',
          description: this.$t('stats.revenue_description')
        }
      ],
      apiArgs: {
        persistent: {
          from: this.buildArgument('from', from => from !== false, this.defaultFrom()),
          to: this.buildArgument('to', to => to !== false, this.defaultTo()),
          show_empty: this.buildArgument('show_empty', null, 1)
        }
      }
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

.stats-account-date .weekday
  color: $secondary-text
.stats-account-date .stars
  font-weight: bold
</style>
