let StatsBaseGridMixin = {
  props: {
    enablePagination: {
      type: Boolean,
      default: false
    },
    enablePerPage: {
      type: Boolean,
      default: false
    },
    enableFilters: {
      type: Boolean,
      default: true
    },
    enableSums: {
      type: Boolean,
      default: true
    },
    enableAvgs: {
      type: Boolean,
      default: true
    },
    enableHeading: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    updateDateRange(from, to) {
      this.apiArgs.persistent.from.value = from
      this.apiArgs.persistent.to.value = to
    },
    updateDate($event, type) {
      this.apiArgs.persistent[type].value = $event
    },
    markPendingImport(date) {
      let stars = ''
      this.pendingImports.forEach(pendingImport => {
        if (pendingImport.date === date) {
          stars = '*'
        }
      })
      return stars
    }
  },
  computed: {
    sum() {
      return this.$store.getters[this.storeName + '/getSum']
    },
    avg() {
      return this.$store.getters[this.storeName + '/getAvg']
    },
    downloadLinkArguments() {
      return {
        url: this.$store.getters[this.storeName + '/getApiUrl'] + '?export=1&download=1&format=csv',
        linkText: this.$t('stats.export'),
        fileName: this.export.fileNamePrefix + '-' + this.apiArgs.persistent.from.value + '-' + this.apiArgs.persistent.to.value + '.csv',
        linkAsButton: true,
        apiArgumentsToUse: this.export.apiArgumentsToUse
      }
    }
  }
}

export default StatsBaseGridMixin
