<template>
  <v-card class="pa-3">
    <v-sheet class="dashboard-card-heading pa-4">
      <Icon :icon="icon" lib="mdi" v-if="icon.indexOf('mdi') > -1" />
      <Icon :icon="icon" lib="fas" v-else />
    </v-sheet>
    <v-sheet class="dashboard-card-content">
      <div class="dashboard-card-title">
        {{ title }}
      </div>
      <div class="dashboard-card-text">
        {{ text }}
      </div>
    </v-sheet>
  </v-card>
</template>

<script>
import Icon from '../app/Icon'

export default {
  components: {
    Icon
  },
  props: {
    icon: String,
    title: String,
    text: {
      type: [Number, String],
      default: 0
    }
  }
}
</script>
