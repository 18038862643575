<template>
  <v-menu v-if="date !== false" :close-on-content-click="true" min-width="auto" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <DatePickerTextField :value="date" v-bind="attrs" :visibility="on" :label="label" :error-message="errorMessage" />
    </template>
    <DatePicker
      @update_date="updateDate($event)"
      :value="date"
      :min-date="calculatedMinDate"
      :max-date="maxDate"
      :allowed-dates="allowedDates"
      :show-week="showWeek"
      :content-class="contentClass"
    />
  </v-menu>
</template>

<script>
import DatePicker from './DatePicker'
import DatePickerTextField from './DatePickerTextField'
import DateHelper from '../../util/DateHelper'

export default {
  name: 'DaySelector',
  components: {
    DatePicker,
    DatePickerTextField
  },
  props: {
    label: {
      String,
      required: true
    },
    errorMessage: {
      String,
      default: null
    },
    date: {
      String,
      required: true
    },
    minDate: {
      type: [String, Boolean, null]
    },
    maxDate: {
      String
    },
    allowedDates: {
      type: Function,
      default: null
    },
    showWeek: {
      Boolean,
      default: false
    },
    contentClass: {
      String
    }
  },
  methods: {
    updateDate(date) {
      this.$emit('update_date', date)
    }
  },
  computed: {
    calculatedMinDate() {
      return DateHelper.toStringOrDefaultMinDate(this.minDate)
    }
  }
}
</script>
