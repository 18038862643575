<template>
  <h1 class="mb-4">{{ typeof text === 'undefined' ? $route.meta.title : text }}</h1>
</template>

<script>
export default {
  name: 'Headline',
  props: {
    text: {
      required: false
    }
  }
}
</script>

<style lang="sass">
h1
  font-size: 26px
  font-weight: 500
  line-height: 32px
  letter-spacing: -0.03em
</style>
