<template>
  <v-date-picker
    @change="updateDate"
    class="mt-4"
    :class="contentClass"
    :min="minDate"
    :max="maxDate"
    :scrollable="isScrollable"
    :no-title="noTitle"
    :first-day-of-week="firstDayOfWeek"
    :allowed-dates="allowedDates"
    :type="type"
    :show-week="showWeek"
  />
</template>

<script>
import DateHelper from '../../util/DateHelper'

export default {
  props: {
    noTitle: {
      Boolean,
      default: true
    },
    isScrollable: {
      Boolean,
      default: true
    },
    type: {
      String,
      default: 'date'
    },
    showWeek: {
      Boolean,
      default: false
    },
    maxDate: {
      String,
      default: function() {
        return DateHelper.todayAsString()
      }
    },
    minDate: {
      String
    },
    firstDayOfWeek: {
      Number,
      default: 1
    },
    allowedDates: {
      type: Function,
      default: null
    },
    contentClass: {
      String
    }
  },
  methods: {
    updateDate(event) {
      this.$emit('update_date', event)
    }
  }
}
</script>
